@import '@styles/variables';

.auth-wrapper {
  position: relative;
  display: flex;
  height: 100%;
  width: 100%;
  min-width: 1280px;

  .auth-content-wrapper {
    position: relative;
    z-index: 99;
    width: 50%;
    background-color: $geyser;
    overflow-y: auto;

    .pl-logo {
      margin-bottom: 8.8rem;
      position: absolute;
      top: 20px;
      left: 20px;
      width: 180px;
    }

    .content {
      height: 100%;
      margin: 0 auto;
      display: flex;
      align-items: center;
      justify-content: center;

      .innerContainer {
        width: 50%;
        text-align: center;

        .heading {
          margin-bottom: 4.3rem;

          font-size: 2.2rem;
          font-family: $font_nunitoSans;
          font-weight: 300;
          line-height: 1.4;
          letter-spacing: 0.02em;
          color: $river_bed1;

          header {
            font-weight: 700;
          }
        }

        .msg {
          margin-top: 2rem;
          margin-bottom: 4.5rem;
          text-align: center;
          line-height: 19px;
          letter-spacing: 0.03em;
          color: $river_bed1;
        }

        .two-field {
          display: flex;

          &>* {
            flex-basis: 100%;

            &:first-of-type {
              margin-right: 2px;
            }
          }
        }

        .edit-actions {
          margin: 3.5rem 0 2.2rem;
        }

        .link-wrap {
          line-height: 23px;
          font-size: 15px;
        }

        .bottom-btn {
          position: absolute;
          bottom: 54px;
          width: inherit;
          text-align: center;
        }

        .bottom-btn,
        .link-wrap {
          a {
            display: block;
            letter-spacing: 0.03em;
          }
        }
      }
    }

    &::-webkit-scrollbar {
      display: none;
    }

    /* Hide scrollbar for IE, Edge and Firefox */
    -ms-overflow-style: none;
    /* IE and Edge */
    scrollbar-width: none;
    /* Firefox */
  }

  .img-section {
    position: relative;
    height: 100vh;
    width: 50%;
    background-color: $geyser;
    text-align: center;

    .navigation-bar {
      position: absolute;
      top: 25px;
      right: 32px;
      z-index: 6;

      & ul {
        list-style: none;

        li {
          a {
            font-size: 1.3rem;
            font-family: $font_ttNormsRegular;
            letter-spacing: 0.03em;
            line-height: 15px;
            color: #465160;
          }

          &:nth-child(2) {
            margin-left: 4rem;
            margin-right: 3.3rem;
          }
        }
      }
    }

    img[alt='pax-img'] {
      position: absolute;
      left: 0;
      z-index: 0;
      width: 50vw;
      height: 100vh;
      object-fit: cover;

      // &:nth-child(1) {
      //   animation: imageAnimation1 14s linear infinite;
      // }
      // &:nth-child(2) {
      //   animation: imageAnimation2 14s linear infinite;
      // }
    }
  }
}

/*

@keyframes imageAnimation1 {
  0% {
    opacity: 1;
  }
  30% {
    opacity: 1;
  }
  40% {
    opacity: 0;
  }
  70% {
    opacity: 0;
  }
  80% {
    opacity: 1;
  }
  100% {
    opacity: 1;
  }
}

@keyframes imageAnimation2 {
  0% {
    opacity: 0;
  }
  30% {
    opacity: 0;
  }
  40% {
    opacity: 1;
  }
  70% {
    opacity: 1;
  }
  80% {
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}
*/