@import '@styles/variables';

.users-wrap {
  width: 790px;
  margin: 0 auto;

  .info-row {
    margin-right: 0.2rem;
    height: 50px;
    min-width: 516px;
  }

  .u-col {
    &-1 {
      width: 130px;
      height: 50px;
    }
    &-2 {
      width: 140px;
      height: 50px;
    }
    &-3 {
      width: 246px;
      height: 50px;
    }
    &-4 {
      width: 120px;
      height: 50px;
    }
    &-5 {
      width: 150px;
      height: 50px;
    }
    &-6 {
      width: 74px;
      height: 50px;
    }
  }

  .email {
    span {
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}

.user-form-control {
  padding: 18px;
  height: inherit;
  width: 100%;

  font-size: 15px;
  letter-spacing: 0.025em;
  color: $river_bed1;
  text-align: left;
  text-overflow: ellipsis;
  border-radius: 0;
  border: 0;
}
