@import '@styles/variables';

.loginFormContainer {
    display: flex;
    justify-content: center; // Horizontally centers the content
    flex-direction: column;

    .loginButton {
        width: 100%;
        margin-top: 20px;
        background-color: $denim;
        background-color: $white;
        letter-spacing: 0.08em;
        padding: 10px;
        font-size: 2.2rem;
        line-height: 1.5;
        font-weight: 700;
        cursor: pointer;
    }

    .linksContainer {
        width: 100%;
        display: flex;
        padding: 10px 0px;
        justify-content: space-between; // Horizontally centers the content
        flex-direction: column;

        .registerButton {
            width: 100%;
            background-color: $secondaryButtonColor;
            color: $white;
            margin-top: 50px;
            letter-spacing: 0.08em;
            padding: 10px;
            font-size: 2.2rem;
            line-height: 1.5;
            font-weight: 700;
            cursor: pointer;
        }
    }
}